import React from 'react'
import Svg from '.'

const Download = props => (
  <Svg viewBox="0 0 24 24" {...props} fill="none">
    <path d="M23 23H1v-8h2v6h18v-6h2z" />
    <path d="M11 1h2v15h-2z" />
    <path d="M12 17.41L4.59 10 6 8.59l6 6 6-6L19.41 10z" />
  </Svg>
)

export default Download
