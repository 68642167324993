import React from 'react'
import Svg from '.'

const Email = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path d="M12 14.35L.93 4.34l1.34-1.48L12 11.65l9.73-8.79 1.34 1.48z" />
    <path d="M24 22H0V2h24v20zM2 20h20V4H2v16z" />
  </Svg>
)

export default Email
