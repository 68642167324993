import React from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'
import Logger from 'utils/logger'
import Subscribe from 'api/subscribe'

const subscribe = new Subscribe()

const INITIAL_STATE = {
  mainMenu: [],
  frontpage: {},
  settings: {
    test: '',
    shippingInfo: '',
    paymentOptionsInfo: '',
  },
  sidebarOpen: false,
}

const { publicRuntimeConfig } = getConfig() || { publicRuntimeConfig: null }

export const AppContext = React.createContext(INITIAL_STATE)

export class AppContextProvider extends React.Component {
  state = INITIAL_STATE

  static getDerivedStateFromProps(props, state) {
    /*
     * Do some other check here?
     * https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
     */
    if (props.mainMenu !== state.mainMenu) {
      return {
        mainMenu: props.mainMenu || state.mainMenu,
        settings: props.settings || state.settings,
        frontpage: props.frontpage || state.frontpage,
        // sidebarOpen: props.sidebarOpen,
      }
    }

    return state
  }

  update(action, payload) {
    this.setState(
      (prevState, props) => {
        const logger = new Logger('AppContextProvider.update', action)
        logger.log()
        logger.log(
          '%cprev state:',
          'color: #9E9E9E; font-weight: bold',
          prevState,
        )
        logger.log('%caction:', 'color: #03A9F4; font-weight: bold', payload)
        logger.end()

        return payload
      },
      () => {
        const logger = new Logger('AppContextProvider.updated', action)
        logger.log(
          '%cnext state:',
          'color: #4CAF50; font-weight: bold',
          this.state,
        )
        logger.end()
      },
    )
  }

  async onSubscribe(mail) {
    let results = {}
    let error = null

    try {
      results = await subscribe.addContact(mail)
    } catch (err) {
      console.log('Subscribe error', err)
      error = err
    }

    return error || results
  }

  componentDidMount() {
    new Logger('AppContextProvider.componentDidMount', this.state).end()

    // if (window.localStorage.getItem('cookieBarClosed') !== '1') {
    //   this.setCookieBarOpen(true)
    // }
  }

  setSidebarOpen = open => this.update('setSidebarOpen', { sidebarOpen: open })

  render() {
    const { children } = this.props

    return (
      <AppContext.Provider
        value={{
          ...this.state,

          setSidebarOpen: this.setSidebarOpen,
          onSubscribe: this.onSubscribe,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContext
