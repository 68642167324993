import React from 'react'
import Svg from '.'

const Close = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M3.585 19L19 3.586 20.414 5 5 20.414z" />
    <path d="M3.585 5L5 3.586 20.414 19 19 20.414z" />
  </Svg>
)

export default Close
