import React from 'react'
import Svg from '.'

const SignupCheck = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M10.1 17.3l-5.2-5.2 1.4-1.4 3.8 3.8 7.8-7.7 1.4 1.4z" />
  </Svg>
)

export default SignupCheck
