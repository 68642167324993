import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'
import Card from 'components/Card'
import Svg from 'components/Svg'
import Typography from 'components/Typography'
import Link from 'components/Link'

export const styles = theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.utils.spacing(4),
      paddingLeft: theme.utils.spacing(4),
    },
    maxWidth: 500,
    '&$noImage': {
      paddingTop: 0,
    },
  },
  container: {
    padding: theme.utils.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.utils.spacing(10),
      paddingLeft: theme.utils.spacing(14),
    },
    '$noImage &': {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.utils.spacing(6),
      },
    },
  },
  social: {
    width: 56,
    height: 44,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 1,
    background: theme.palette.primary.main,

    '& svg': {
      fill: theme.palette.white,
    },
  },
  name: {
    color: theme.palette.black,
    marginBottom: theme.utils.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleBlock: {
    ...theme.typography.body2,
    '& > * + *': {
      marginBottom: theme.utils.spacing(5),
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  image: {
    marginRight: theme.utils.spacing(5),
    width: 120,
    height: 160,
    objectFit: 'cover',
    objectPosition: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: `-${theme.utils.unit * 4}px`,
      top: `-${theme.utils.unit * 4}px`,
    },
  },
  secondaryImage: {
    maxHeight: 60,
    height: 'auto',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  noImage: {},
})

const ContactCard = props => {
  const {
    classes,
    className: classNameProp,
    name,
    description,
    image,
    secondaryImage,
    social,
    ...other
  } = props

  const hasContact = name && description
  const hasPrimaryImg = Boolean(image)

  const className = classnames(
    classes.root,
    {
      [classes.noImage]: hasPrimaryImg === false,
    },
    classNameProp,
  )

  return (
    <>
      {hasContact && (
        <div className={className} {...other}>
          <Card>
            <div className={classes.container}>
              <Typography className={classes.name} variant="h3">
                {name}
              </Typography>
              <Typography
                className={classes.titleBlock}
                component="div"
                dangerouslySetInnerHTML={{ __html: description }}
              />
              {social && (
                <div className={classes.social}>
                  <Link href={social}>
                    <Svg.Linkedin width="24" />
                  </Link>
                </div>
              )}
              <div className={classes.imgContainer}>
                {image && <img src={image} className={classes.image} />}
                {secondaryImage && (
                  <img
                    src={secondaryImage}
                    className={classes.secondaryImage}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  )
}

ContactCard.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  social: PropTypes.string,
  name: PropTypes.string.isRequired,
  secondaryImage: PropTypes.string,
}

ContactCard.defaultProps = {
  className: '',
  image: '',
  description: '',
  social: '',
  secondaryImage: '',
}

ContactCard.uiName = 'ContactCard'

export default withStyles(styles)(ContactCard)
