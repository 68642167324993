import React from 'react'
import Svg from '.'

const Burger = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M.7 19h22.7v2H.7zM.7 11h22.7v2H.7zM.7 3h22.7v2H.7z" />
  </Svg>
)

export default Burger
