import React from 'react'
import Svg from '.'

const Checkbox = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M10 17.4L4.6 12 6 10.6l4 4 8-8L19.4 8z" />
    <path d="M23 23H1V1h22v22zM3 21h18V3H3v18z" />
  </Svg>
)

export default Checkbox
