import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NextLink from 'next/link'
import { componentPropType } from '@material-ui/utils'
import withStyles from 'components/styles/withStyles'

export const styles = theme => ({
  root: {
    color: 'inherit',
    textDecoration: 'none',
  },
  enableUnderline: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    transition: 'border 0.1s linear',
    '&:hover': {
      borderBottomColor: theme.palette.accent.purple,
    },
  },
})

const Link = props => {
  const {
    children,
    classes,
    className: classNameProp,
    enableUnderline,

    href,
    as,
    component,

    // Props from Button should not be spread on LinkComponent
    onBlur,
    onFocus,
    onKeyDown,
    onKeyUp,
    onMouseDown,
    onMouseLeave,
    onMouseUp,
    onTouchEnd,
    onTouchMove,
    onTouchStart,
    onContextMenu,
    tabIndex,
    role,

    ...other
  } = props

  const LinkComponent = component
  const openNewTab = href.indexOf('http') > -1 ? '_blank' : ''

  return (
    <LinkComponent href={href} as={as}>
      <a
        className={classnames(
          classes.root,
          { [classes.enableUnderline]: enableUnderline },
          classNameProp,
        )}
        {...other}
        target={openNewTab}
      >
        {children}
      </a>
    </LinkComponent>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: componentPropType,
  classes: PropTypes.object.isRequired,
  enableUnderline: PropTypes.bool,
  as: PropTypes.string,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.object,
    }),
  ]),
}

Link.defaultProps = {
  className: '',
  children: null,
  href: '',
  as: '',
  enableUnderline: false,
  component: NextLink,
}

Link.uiName = 'Link'

export default withStyles(styles)(Link)
