import React from 'react'
import Svg from '.'

const CopyCheck = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M19 24H1V4h18v20zM3 22h14V6H3v16z" />
    <path d="M23 22h-2V2H4V0h19z" />
    <path d="M8.69 17.91L4.79 14l1.41-1.41 2.51 2.5 5.1-5 1.4 1.42z" />
  </Svg>
)

export default CopyCheck
