import React from 'react'
import Svg from '.'

const Linkedin = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M7.1 20.5H3.6V9h3.6l-.1 11.5zM5.3 7.4c-1.1 0-2.1-.9-2.1-2.1 0-1.1.9-2.1 2.1-2.1 1.1 0 2.1.9 2.1 2.1s-.9 2.1-2.1 2.1zm15.2 13.1h-3.6v-5.6c0-1.3 0-3-1.8-3-1.9 0-2.1 1.4-2.1 2.9v5.7H9.4V9h3.4v1.6c.5-.9 1.6-1.8 3.4-1.8 3.6 0 4.3 2.4 4.3 5.5v6.2z" />
  </Svg>
)

export default Linkedin
