import React from 'react'
import Svg from '.'

const ArrowInternal = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M1.294 21.291L21.291 1.295l1.414 1.414L2.71 22.705z" />
    <path d="M19.1 23H1V4.9h2V21h16.1z" />
  </Svg>
)

export default ArrowInternal
