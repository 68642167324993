import React from 'react'
import Svg from '.'

const ArrowRight = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M9.21 19.81l-1.42-1.42 6.4-6.39-6.4-6.39 1.42-1.42 7.8 7.81z" />
  </Svg>
)

export default ArrowRight
