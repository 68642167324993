import React from 'react'
import Svg from '.'

const CheckboxOutline = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M23 23H1V1h22v22zM3 21h18V3H3v18z" />
  </Svg>
)

export default CheckboxOutline
