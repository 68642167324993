import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'components/styles/withStyles'

export const styles = theme => ({
  '@keyframes gradientAnimation': {
    '0%': { backgroundPosition: '0% 24%' },
    '50%': { backgroundPosition: '100% 77%' },
    '100%': { backgroundPosition: '0% 24%' },
  },
  root: {
    width: '100%',
    height: 'auto',
    padding: '3px',
    position: 'relative',
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${
      theme.palette.turquoise[200]
    })`,
    backgroundSize: '200% 200%',
    animation: `gradientAnimation 4s ease infinite`,
  },
  container: {
    background: theme.palette.white,
  },
})

const Card = props => {
  const { children, classes, className, ...other } = props

  return (
    <div className={classnames(classes.root, className)} {...other}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
}

Card.uiName = 'Card'

export default withStyles(styles)(Card)
