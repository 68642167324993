export default class {
  constructor(title, message = '') {
    const logStyle = [
      'font-weight: normal; color: gray',
      'font-weight: bold; color: unset',
    ]
    if (process.browser) {
      console.groupCollapsed(
        `[${new Date().toLocaleString()}] %c${title} %c${message}`,
        ...logStyle,
      )
    } else {
      console.groupCollapsed(
        `[${new Date().toLocaleString()}] ${title} ${message}`,
      )
    }
  }

  log(...data) {
    console.log(...data)
  }

  end() {
    console.groupEnd()
  }
}
