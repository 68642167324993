import React from 'react'
import Svg from '.'

const ArrowExternal = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M1.294 21.291L21.291 1.295l1.414 1.414L2.71 22.705z" />
    <path d="M23 19.1h-2V3H4.9V1H23z" />
  </Svg>
)

export default ArrowExternal
