import React from 'react'
import PropTypes from 'prop-types'
import ArrowInternal from './ArrowInternal'
import ArrowExternal from './ArrowExternal'
import ArrowRight from './ArrowRight'
import Email from './Email'
import Linkedin from './Linkedin'
import Download from './Download'
import Copy from './Copy'
import CICSLogoAnimated from './CICSLogoAnimated'
import Burger from './Burger'
import Close from './Close'
import CopyCheck from './CopyCheck'
import Checkbox from './Checkbox'
import CheckboxOutline from './CheckboxOutline'
import SignupCheck from './SignupCheck'

const Svg = ({ children, innerRef, ...rest }) => (
  <svg viewBox="0 0 100 100" {...rest} ref={innerRef}>
    {children}
  </svg>
)

Svg.propTypes = {
  innerRef: PropTypes.func,
  children: PropTypes.node.isRequired,
}

Svg.defaultProps = {
  innerRef: () => {},
}

Svg.ArrowExternal = ArrowExternal
Svg.ArrowInternal = ArrowInternal
Svg.ArrowRight = ArrowRight
Svg.Burger = Burger
Svg.Checkbox = Checkbox
Svg.CheckboxOutline = CheckboxOutline
Svg.CICSLogoAnimated = CICSLogoAnimated
Svg.Close = Close
Svg.Copy = Copy
Svg.CopyCheck = CopyCheck
Svg.Download = Download
Svg.Email = Email
Svg.Linkedin = Linkedin
Svg.SignupCheck = SignupCheck

export default Svg
