import React from 'react'
import Svg from '.'

const Copy = props => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path d="M19 24H1V4h18v20zM3 22h14V6H3v16z" />
    <path d="M23 22h-2V2H4V0h19z" />
  </Svg>
)

export default Copy
