import getConfig from 'next/config'
import unfetch from 'isomorphic-unfetch'

const nextConfig = getConfig()
let serverRuntimeConfig = null
let publicRuntimeConfig = null

if (nextConfig) {
  serverRuntimeConfig = nextConfig.serverRuntimeConfig
  publicRuntimeConfig = nextConfig.publicRuntimeConfig
}

export default class Subscribe {
  constructor() {
    let base = 'http://localhost:3000'

    if (nextConfig) {
      base = process.browser
        ? publicRuntimeConfig.HOST
        : serverRuntimeConfig.HOST
    }

    this.url = `${base}/api/subscribe`
  }

  async addContact(mail) {
    const response = await unfetch(this.url, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: mail !== '' ? JSON.stringify({ mail }) : undefined,
    })

    return response
  }
}
