import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { componentPropType } from '@material-ui/utils'
import MuiButton from '@material-ui/core/Button'
import withStyles from 'components/styles/withStyles'
import Link from 'components/Link'
import Svg from 'components/Svg'

const styles = theme => ({
  root: {
    ...theme.typography.button,
    background: 'inherit',
    width: '100%',
    maxWidth: 200,
    textTransform: 'uppercase',
    padding: '13px 10px',
    color: theme.palette.tertiary,
    textAlign: 'center',
    transition: 'background 0.2s linear',
  },
  primary: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.accent.purple,
      '@media (hover: none)': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&$disabled': {
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },
  secondary: {
    background: theme.palette.secondary.dark,
    '&:hover': {
      background: theme.palette.secondary.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    '&$disabled': {
      '&:hover': {
        background: theme.palette.secondary.dark,
      },
    },
  },
  tertiary: {
    background: theme.palette.accent.turquoise,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.turquoise[300],
      '@media (hover: none)': {
        backgroundColor: theme.palette.accent.turquoise,
      },
    },
    '&$disabled': {
      '&:hover': {
        background: theme.palette.accent.turquoise,
      },
    },
  },
  iconOpt: {
    justifyContent: 'space-between',
    padding: '12px 15px',
  },
  icon: {
    marginLeft: 10,
    width: 20,
    fill: theme.palette.white,
    '$tertiary & ': {
      fill: 'currentColor',
    },
  },
  disabled: {},
})

const Button = props => {
  const {
    className: classNameProp,
    classes,
    children,
    color,
    href,
    disabled,
    component,
    ...other
  } = props

  let linkType = ''
  if (component === Link) {
    linkType = href.indexOf('http') > -1 ? 'external' : 'internal'
  }

  const className = classnames(
    classes.root,
    {
      [classes.primary]: color === 'primary' || linkType === 'external',
      [classes.secondary]: color === 'secondary' || linkType === 'internal',
      [classes.tertiary]: color === 'tertiary',
      [classes.iconOpt]: linkType,
      [classes.disabled]: disabled,
    },
    classNameProp,
  )

  return (
    <MuiButton
      component={component}
      href={href}
      className={className}
      {...other}
    >
      {children}
      {linkType && linkType === 'external' && (
        <Svg.ArrowExternal width="24" className={classes.icon} />
      )}
      {linkType && linkType === 'internal' && (
        <Svg.ArrowInternal width="24" className={classes.icon} />
      )}
    </MuiButton>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  component: componentPropType,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  children: 'Learn more',
  color: 'primary',
  href: '',
  component: 'button',
  disabled: false,
}

Button.uiName = 'Button'

export default withStyles(styles)(Button)
